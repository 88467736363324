<template>
  <div>
    <v-row>
       <v-col cols="12" md="5">
        <v-card height="100%">
          <v-card-title>
            <span>Fuel Cell</span>
          </v-card-title>
          <v-card-text v-if="fuelCell.lastUpdate">
            <v-list-item>
              <v-list-item-icon>
                <v-icon large :color="fuelCell.online ? 'green' : 'red'">mdi-connection</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ fuelCell.online ? 'Online' : 'Offline' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="green">mdi-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="fuelCell.lastUpdate">Last Update: {{ fuelCell.lastUpdate | moment("MM/DD/YYYY - h:mm a") }}</v-list-item-title>
                <v-list-item-title v-else>Last Update</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="blue">mdi-water</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Water Level{{ fuelCell.online ? `: ${fuelCell.water}%` : "" }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon large color="orange">mdi-fuel</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Fuel Pressure{{ fuelCell.online ? `: ${fuelCell.fuel} psi` : "" }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card v-if="fuelCell">
          <v-card-title>
            <span>Sensors</span>
          </v-card-title>
          <v-data-table
            v-if="sensors.length"
            :headers="sensorHeaders"
            :items="sensors"
            :items-per-page="50"
            item-key="id"
            :search="sensorSearch"
            sort-by="name"
            :mobile-breakpoint="0"
            hide-default-footer
          >
            <template v-slot:body="{items}">
              <tbody>
                <template v-for="item in items">
                  <tr :key="item.id">
                    <td>
                      <v-icon
                        medium
                        :color="item.online ? 'green' : 'red'"
                      >mdi-checkbox-blank-circle</v-icon>
                    </td>
                    <td>
                      <v-edit-dialog
                        :return-value.sync="item.name"
                        @save="editItem(item)"
                        large
                        :color="siteColor"
                      >
                        {{ item.name }}
                        <template v-slot:input>
                          <v-text-field
                          :color="siteColor"
                            v-model="item.name"   
                            hide-details=""                         
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </td>
                    <td>{{ item.currentTemp }}℉</td>
                    <td>{{ item.lastCheckIn | moment("MM/DD/YYYY - h:mm a") }}</td>
                    <td>
                      <v-icon
                        medium
                        :color="item.recording ? 'green' : 'red'"
                      >mdi-checkbox-blank-circle</v-icon>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card v-if="fuelCell">
          <v-card-title>
            <span>Treatments</span>
            <v-icon @click="addTreatment" class="ml-1" :color="siteColor">mdi-plus-circle</v-icon>
            <div class="flex-grow-1"></div>
            <v-text-field
              xs12
              :color="siteColor"
              v-model="treatmentSearch"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-if="treatments.length"
            :headers="treatmentHeaders"
            :items="treatments"
            :items-per-page="50"
            item-key="id"
            :search="treatmentSearch"
            sort-by="createdAt"
            sort-desc
            :mobile-breakpoint="0"
          >
            <template v-slot:body="{items}">
              <tbody>
                <template v-for="item in items">
                  <tr @click="routeTo(item.id)" :key="item.id">
                    <td>
                      <v-icon
                        medium
                        :color="item.active ? 'green' : 'red'"
                      >mdi-checkbox-blank-circle</v-icon>
                    </td>
                    <td>{{ item.id.slice(0,8) }}</td>
                    <td>{{ item.createdAt | moment("MM/DD/YYYY - h:mm a") }}</td>
                    <td>{{ item.readings.items.length }}</td>
                    <td>{{ item.murphyTemps.items.length }}</td>
                    <td v-if="!item.readings.items.length">
                        <v-btn v-if="!item.active" :loading="btnLoading" icon text @click.stop="updateTreatment(item)">
                        <v-icon
                          color="green"
                        >mdi-play-circle
                        </v-icon>
                      </v-btn>
                      <v-btn v-else :loading="btnLoading" icon text @click.stop="updateTreatment(item)">
                        <v-icon
                          color="green"
                        >mdi-pause-circle
                        </v-icon>
                      </v-btn>
                      <!-- <v-btn icon @click.stop="removeTreatment(item.id)">
                       <v-icon
                      color="red"
                    >mdi-delete</v-icon>
                     </v-btn> -->
                      <!-- <v-btn :loading="btnLoading" icon @click.stop="updateTreatment(item)">
                         <v-icon
                      color="green"
                    >mdi-pause-circle</v-icon>
                      </v-btn> -->
                      <!-- <v-btn @click.stop="updateTreatment(item)">{{ item.active ? 'Stop' : 'Start' }}</v-btn> -->
                    </td>
                   <td v-else-if="item.active">
                      <v-btn :loading="btnLoading" icon @click.stop="updateTreatment(item)">
                        <v-icon
                        color="green"
                        >mdi-pause-circle
                        </v-icon>
                      </v-btn>
                   </td>
                   <td v-else>
                     <v-btn icon @click.stop="removeTreatment(item.id)">
                       <v-icon
                      color="red"
                    >mdi-delete</v-icon>
                     </v-btn>
                     <!-- <v-btn v-if="!item.active" :loading="btnLoading" icon text @click.stop="updateTreatment(item)">
                        <v-icon
                          color="green"
                        >mdi-play-circle
                        </v-icon>
                      </v-btn>
                     <v-btn v-else icon @click.stop="removeTreatment(item.id)">
                       <v-icon
                      color="red"
                    >mdi-delete</v-icon>
                     </v-btn> -->
                  </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      btnLoading: false,
      treatmentSearch: "",
      sensorSearch: "",
      currentItem: "",
      treatmentHeaders: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Created",
          value: "createdAt",
        },
        {
          text: "Readings",
          value: "",
        },
        {
          text: "Fuel Cell Readings",
          value: "",
        },
        
        {
          text: "",
          value: "",
        },
      ],
      sensorHeaders: [
        {
          text: "Online",
          value: "online",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Current Temp",
          value: "currentTemp",
        },
        {
          text: "Last Update",
          value: "lastCheckIn",
        },
        {
          text: "Recording",
          value: "recording",
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch(
      "fuelcell/singleFuelCell",
      this.$route.params.id
    );
  },
  computed: {
    ...mapState("fuelcell", ["fuelCell"]),
    ...mapGetters("fuelcell", ["treatments", "sensors"]),
    currentTreatments() {
      return this.treatments.filter(item => item.active || !item.readings.items.length)
    }
  },
  methods: {
    async addTreatment() {
      if (this.currentTreatments.length) return
      await this.$store.dispatch("fuelcell/addTreatment")
    },
    async removeTreatment(id) {
      await this.$store.dispatch("fuelcell/removeTreatment", id)
    },
    routeTo(route) {
      this.$router.push(`/treatment/${route}`);
    },
    async editItem(item) {
      await this.$store.dispatch("sensor/updateSensorProperty", {
        id: item.id,
        prop: "name",
        value: item.name
      });
    },
    async updateTreatment(item) {
      this.btnLoading = true
      let action = true;
      if (item.active) action = false;
      await this.$store.dispatch("fuelcell/updateTreatmentAndSensors", {
        action,
        id: item.id,
        sensors: item.fuelCell.sensors.items.map((item) => item.id),
      });
      this.btnLoading = false
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card{
  background-color: #fbfbfb;
  border: 1px solid #e2e2e2;
}
.v-data-table{
  background-color: #fbfbfb;
}
tr {
  cursor: pointer;
}
table {
  .v-text-field {
    border-color: #ffffff1f;
    padding-top: 0px;
    margin-top: 0px;
  }
}
i:focus {
  outline: none;
}
</style>